<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $route.params.projectInfo.tenderName }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3 primary" />
    <v-row>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('stage_label') }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3 gray-color" />
    <v-row
      justify="start"
    >
      <v-col cols="3">
        <v-btn
          large
          color="primary"
          rounded
          @click="() => { showModalSaveStage = true }"
        >
          {{ $t('add_stage') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          color="quaternary"
        >
          <app-generic-table
            ref="genericTable"
            :table-fields="$_tableHeaderMixin_generate"
            :actions="true"
            :api-url="apiUrl"
            :disabled-default-params="true"
            :search="{
              tenderId: $route.params.projectInfo.id
            }"
            disable-pagination
            @edit-row="editProject"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end">
      <v-col cols="2">
        <v-btn
          color="gray_color lighten-1 white--text"
          class="mt-12 mr-4"
          block
          @click="toReturn"
        >
          {{ $t('exit') }}
        </v-btn>
      </v-col>
    </v-row>
    <save-new-stages
      :show-modal="showModalSaveStage"
      title-modal="Crear etapa"
      label-field="Nombre etapa"
      agree-text-bottom="Confirmar"
      disagree-text-bottom="Cancelar"
      @resolve="resolveSaveNewStage"
    />
  </v-container>
</template>

<script>
import tableHeaderMixin from '@/mixins/tableHeaderMixin'
import AppGenericTable from '@/components/uiComponents/genericTable/AppGenericTable'
import { GET_PROJECT_STAGES } from './data/stageSettingsConstant'
import stageSettingsHeadersTable from './data/stageSettingsHeadersTable'
import { mapState } from 'vuex'
import SaveNewStages from '@/components/uiComponents/modals/saveNewStages/SaveNewStages'
import { ADD_COUNTER_REQUEST, SHOW_ALERT, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'
import { CONFLICT } from '@/shared/constants/httpStatus'
/**
 * Component to list stages in the application.
 *
 * @author Andres Correa
 * @version 1.0.0
 * @since 15/07/2020 01:51 PM
 */
export default {
  name: 'StageSettings',
  components: {
    AppGenericTable,
    SaveNewStages
  },
  mixins: [tableHeaderMixin],
  beforeRouteEnter (to, from, next) {
    if (to.params.projectInfo && to.params.projectInfo.id) {
      next()
    } else {
      next({ name: 'projects' })
    }
  },
  data () {
    return {
      apiUrl: GET_PROJECT_STAGES,
      columnOptions: stageSettingsHeadersTable,
      showModalSaveStage: false
    }
  },
  computed: {
    /**
     * This property computed get userInfo objet that response back
     *
     * @author Andres Correa
     * @since 16/07/2020 10:20 PM
     * @version 1.0.0
     */
    ...mapState({ userInfo: (state) => state.security.userInfo })
  },
  methods: {
    /**
     * This method redirects to the view sections
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 15/07/2020 10:30 AM
     */
    editProject (stage) {
      this.$router.push({
        name: 'SettingsSections', params: { projectInfo: this.$route.params.projectInfo, infoStage: stage }
      })
    },
    /**
     * This method received answer from modal for create or no a new stage
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 10:30 AM
     */
    resolveSaveNewStage (response) {
      this.showModalSaveStage = false
      if (response) {
        this.addStagesProjects(response)
      }
    },
    /**
     * This method redirects to the projects page
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 10:30 AM
     */
    toReturn () {
      this.$router.push({
        name: 'projects'
      })
    },
    /**
     * This method add new stage to tender
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 10:30 AM
     */
    addStagesProjects (nameStage) {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.post(`${GET_PROJECT_STAGES}`,
        { tenderId: this.$route.params.projectInfo.id, name: nameStage }
      ).then((response) => {
        this.$refs.genericTable.getDataFromApi()
      }).catch((error) => {
        if (error.response.status === CONFLICT) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('error'),
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    }
  }
}
</script>
<style>
  .v-btn.primary > span {
  color: #424242;
}
</style>
<i18n src="./data/i18nMessage.json" />
