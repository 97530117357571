<template>
  <v-dialog
    v-model="showModal"
    :persistent="persistent"
    max-width="510"
  >
    <v-card class="pa-8">
      <v-card-title class="d-flex justify-center pb-0 pt-8 subtitle-2 text-center">
        {{ titleModal }}
      </v-card-title>
      <ValidationObserver
        ref="obs"
        v-slot="{on, handleSubmit}"
      >
        <v-col
          cols="12"
        >
          <div>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="SaveNewStageValidations.stageName"
              name="Nombre etapa"
              vid="encrypt"
            >
              <v-text-field
                id="fieldnameStage"
                v-model="nameStage"
                :error-messages="errors"
                label="Nombre etapa"
                type="text"
                outlined
                rounded
                v-on="on"
              />
            </ValidationProvider>
          </div>
        </v-col>
        <v-card-actions class="justify-center justify-space-around mt-4">
          <v-btn
            color="primary secondary--text px-6"
            large
            @click="handleSubmit(agree)"
          >
            {{ agreeTextBottom }}
          </v-btn>
          <v-btn
            color="primary secondary--text px-6"
            large
            @click="disagree"
          >
            {{ disagreeTextBottom }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import SaveNewStageValidations from './data/saveNewStageValidations'
export default {
  name: 'ModalStage',
  props: {
    showModal: {
      type: Boolean,
      required: true,
      description: 'Controla cuando muestra la modal'
    },
    titleModal: {
      type: String,
      required: true,
      description: 'Mensaje de la modal'
    },
    agreeTextBottom: {
      type: String,
      required: true,
      description: 'Mensaje botón para aceptar'
    },
    disagreeTextBottom: {
      type: String,
      required: true,
      description: 'Mensaje botón para cancelar'
    },
    persistent: {
      type: Boolean,
      default: true,
      description: 'La modal no se cierra al presionar fuera de ella'
    }
  },
  data () {
    return {
      SaveNewStageValidations,
      dialog: false,
      nameStage: ''
    }
  },
  methods: {
    agree () {
      this.$emit('resolve', this.nameStage)
      this.nameStage = ''
    },
    disagree () {
      this.$emit('resolve', false)
    }
  }
}
</script>
