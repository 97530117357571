'use strict'

/**
 * Constants file with the endpoints used by role management module to call a REST-services of the backend API.
 *
 * @type {number}
 *
 * @author Andres Correa
 * @since 18/02/2020 10:25 AM
 * @version 1.0.0
 */

/* Backend endpoints */
const PUBLIC_BASE_URL = process.env.VUE_APP_API_URL
export const GET_PROJECT_STAGES = `${PUBLIC_BASE_URL}service/stage`
